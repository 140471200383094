<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            label="년도"
            type="year"
            v-model="searchParam.year"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            :editable="editable"
            label="부서"
            :isFirstValue="false"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="부서별 월별 고장 발생건수 목록"
          tableId="equipmentTable"
          :columns="grid.columns"
          :data="grid.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'discardFlag'">
              <q-chip
                :color="props.row.discardFlag=='WESC000001' ? 'blue' : (props.row.discardFlag=='WESC000002' ? 'red' : 'orange')"
                text-color="white">
                {{props.row.discardFlagName}}
              </q-chip>
            </template>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'dept-type-break-status',
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        year: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      discardFlagItems: [
        { code: 'N', codeName: '가동중' },
        { code: 'Y', codeName: '고장' },
        { code: 'X', codeName: '불용' },
      ],
      grid: {
        columns: [
        ],
        data: [],
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.searchParam.year = this.$comm.getThisYear();
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sts.bka.dept.url;
      this.getList();
      this.setHeader();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '년도를 선택하세요.', // 년도를 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setHeader() {
      let columns = [
        {
          name: 'plantName',
          field: 'plantName',
          label: '사업장',
          align: 'center',
          sortable: true,
          style: 'width: 120px',
        },
        {
          name: 'deptName',
          field: 'deptName',
          label: '부서',
          align: 'left',
          sortable: false,
        },
      ];
      let months = [];
      for (let i=1; i<=12; i++) {
        months.push({
          name: 'mon' + i,
          field: 'mon' + i,
          label: i + '월',
          align: 'right',
          sortable: true,
          style: 'width: 80px',
        })
      }
      this.grid.columns = this.$_.concat(columns, {
        name: 'year',
        field: 'year',
        label: '월별 고장건수(건)',
        child: months
      })
      this.grid.columns.push({
        name: 'sum',
        field: 'sum',
        label: '총 합계(건)',
        align: 'right',
        currentDay: true,
        sortable: true,
        style: 'width: 80px',
      })
    },
  }
};
</script>
